export default [
    {
        title: 'Back',
        route: 'store-back',
        icon: 'ChevronLeftIcon',
    },
    {
        header: 'Store',
    },

    {
        title: 'Store',
        route: 'store',
        icon: 'CoffeeIcon',
    },
    {
        title: 'Add Store',
        route: 'store-add',
        icon: 'PlusIcon',
    },
    {
        title: 'Edit Store',
        route: 'store-edit',
        icon: 'EditIcon',
    },
   

]
